.header {
  /* border-bottom        : 1px solid #2F2483; */
  left                 : 0px;
  top                  : 0px;
  right                : 0px;
  bottom               : auto;
  width                : 100%;
  height               : 53px;
  max-height           : 52;
  padding              : 0px;
  z-index              : auto;
  position             : absolute;
  background       : #2F2483;
  background-repeat    : no-repeat;
  background-size      : 100% auto;
  display              : table;
  vertical-align       : 'middle';
}



#sc-header-left {
  min-width: 110px;
}

#sc-header-center {
  width: 100%;
}

#sc-header-right {
  width        : 300px;
  text-align   : right;
  display      : table-cell;
  padding-right: 15px;
  min-width    : 237px;
  padding-top  : 5px;
}

.sc-header-right-buttons {
  background-color: transparent;
  border          : none;
  cursor          : pointer;
  width           : 84px;
  height          : 37px;
  border          : 1px solid transparent;
}

.sc-header-right-buttons:hover {
  border    : 1px solid #2074b7;
  box-shadow: 0px 0px 5px #80b9ff;
}

#sc-header-help-button:focus {
  outline: 0;
}

#sc-header-advanced-button {
  width      : 80px;
  margin-left: 5px;
}

#sc-header-burger-button {
  display       : table-cell;
  cursor        : pointer;
  margin-left   : 12px;
  width         : 33%;
  height        : 44px;
  vertical-align: middle;
  text-align    : left;
  padding-top   : 2px;
  padding-left: 21px;
  outline       : none;
}


.sc-header-dot-menu-container {
  display       : table-cell;
  padding-right : 11px;
  width         : 20%;
  vertical-align: middle;
  text-align: right;
}

@media (max-width: 767px) {
  .sc-header-dot-menu-container {
    display       : table-cell;
    cursor        : pointer;
    margin-left   : 12px;
    width         : 46px;
    height        : 44px;
    vertical-align: middle;
    text-align    : left;
    padding-left  : 21px;
    padding-top   : 2px;
  }
}

.sc-header-username {
  display       : table-cell;
  text-align    : right;
  color         : #FFFFFF;
  margin-right  : 21px;
  vertical-align: middle;
  width         : 13%;
}

@media (max-width: 767px) {
  .sc-header-username {
    display: none;
  }
}

.sc-header-logo {
  display        : table-cell;
  height         : 52px;
  width          : auto;
  border         : 0px solid;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

@media (max-width: 767px) {
  .sc-header-logo {
    display: none;
  }
}

.sc-header-dot-menu-img:hover {
  background-color: #2F2483;
}

/* .sc-header-dot-menu-container{
  width: 39px;
  text-align: center;
  height: 32px;
  padding-top: 8px;
  margin-left: 15px;
  display: table-cell;
}

  .sc-header-dot-menu-container:hover{
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
  } */

.sc-header-feedback-container {
  width         : 40px;
  text-align    : center;
  vertical-align: middle;
  display       : table-cell;
  cursor        : pointer;
}

#sc-header-bar-logo {
  display       : table-cell;
  vertical-align: bottom;
  padding-left  : 9px;
  width         : 111px;
}

#sc-header-bar-button {
  display: none;
}

#sc-header-search-container {
  display         : table-cell;
  padding-top     : 6px;
  /* padding-right: 12px; */
}

/* MOBILE */
@media only screen and (max-width: 770px) {
  #sc-header-burger-button {
    display       : table-cell;
    cursor        : pointer;
    margin-left   : 12px;
    width         : 80%;
    height        : 44px;
    vertical-align: middle;
    text-align    : left;
    padding-left  : 21px;
    padding-top   : 2px;
  }

  #sc-header-bar-button {
    display             : none;
    /* display          : block;
        cursor          : pointer;
        margin-left     : 0px;
        width           : 46px;
        height          : 44px;
        background-image: url(./images/bar-button.png); */
  }

  #sc-header-bar-logo {
    display: none;
  }

  #sc-header-right {
    display: none;
  }

  #sc-header-left {
    min-width: 47px;
  }

  #sc-header-center {
    width        : 100%;
    padding-right: 13px;
  }
}