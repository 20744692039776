.simcoe-sidebarlist-item {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  min-height: 72px;
  padding: 5px;
  border-radius: 2px;
  color: #505050;
  margin-top: 5px;
}

.simcoe-sidebarlist-item-icon {
  text-align: center;
  opacity: 0.7;
}
.simcoe-sidebarlist-item-iconbackground {
  float: left;
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  text-align: center;
  padding-top: 5px;
  border-radius: 3px;
  background: linear-gradient(to bottom, rgba(242, 245, 246, 1) 0%, rgba(230, 230, 230, 1) 100%);
}
.simcoe-sidebarlist-item-text-container {
  margin-left: 75px;
}

.simcoe-sidebarlist-item-text-title {
  font-size: 15px;
  font-weight: bold;
  color: #616161;
}

.simcoe-sidebarlist-item-text-description {
  font-size: 10px;
  color: #777;
  padding-left: 18px;
  background-image: url(./images/arrow_curve.gif);
  background-repeat: no-repeat;
  padding-right: 5px;
}

.simcoe-sidebarlist-container {
  position: absolute;
  top: 66px;
  bottom: 0px;
  overflow-y: auto;
}
