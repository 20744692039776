
  .sc-loading-screen {
    width:100%;
    height: 100%;
    position: fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    overflow: hidden;
    z-index: 99999999999;
    background:rgba(0,0,0,0.35);
  }
  .sc-loading-screen > .spinner{
    position: fixed;
    top:50%;
    right:50%;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .sc-loading-screen > .message{
    position: fixed;
    top:50%;
    width:100%;
    font-weight: bolder;
    text-align: center;
  }