.sc-mymaps-footer-buttons {
  height: 33px;
  bottom: 0px;
  width: 97%;
  padding-top: 6px;
  border-top: 1px solid #bbb;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 10px;
}

.sc-mymaps-footer-button {
  width: 48%;
  cursor: pointer;
  display: inline-table;
}

.sc-mymaps-footer-button.delete {
  background-color: #e5e5e5;
  background-image: url(./images/eraser.png);
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 26px;
  text-align: center;
}

.sc-mymaps-footer-button.tools {
  background-color: #e5e5e5;
  background-image: url(./images/toolbox.png);
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 15px;
  margin-left: 5px;
  text-align: center;
}
