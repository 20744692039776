.sc-sidebar-slim-container {
  width                 : 65px;
  height                : 100%;
  position              : absolute;
  /* top                   : 1px; */
  right                 : -65px;
  text-align            : center;
  -webkit-user-select   : none;
  -moz-user-select      : none;
  -ms-user-select       : none;
  user-select           : none;
  /* background-color   : rgba(19, 67, 144, 0.4); */
  background            : #2F2483;
  padding-bottom        : 5px;
  padding-left          : 2px;
  padding-right         : 1px;
    color: white;
}

.sc-sidebar-slim-button-container {
  padding-top: 10px;
    color: white;
}

.sc-sidebar-slim-button-container.active {
  background-color: #e73420;
}

.sc-sidebar-slim-button-container:hover {
  background-color: #9e9e9e;
}

.sc-sidebar-slim-button {
  display     : contents;
  border      : none;
  padding-top : 10px;
  padding-left: 8px;
  font-size   : 9pt;
    color: white;

}

.sc-sidebar-slim-button-dot {
  height          : 10px;
  width           : 10px;
  background-color: rgb(120, 204, 50);
  border-radius   : 50%;
  display         : inline-block;
  position        : relative;
  top             : -46px;
  right           : -2px;
  bottom          : auto;
  left            : auto;
}

.sc-tab-button-dot-tools {
  height          : 10px;
  width           : 10px;
  background-color: rgb(120, 204, 50);
  border-radius   : 50%;
  display         : inline-block;
  position        : absolute;
  top             : 74px;
  right           : 5px;
}

.sc-tab-button-dot-themes {
  height          : 10px;
  width           : 10px;
  background-color: rgb(120, 204, 50);
  border-radius   : 50%;
  display         : inline-block;
  position        : absolute;
  top             : 202x;
  right           : 5px;
}

.sc-sidebar-slim-footer-button {
  display      : block;
  max-width    : 62px;
  min-width    : 62px;
  margin-bottom: 5px;
  font-size    : 8pt;
  font-weight  : bold;
  text-align   : center;
  height       : 20px;
  padding-left : 1px;
}

.sc-sidebar-slim-footer-button:hover {
  border    : 1px solid #80b9ff;
  box-shadow: 0px 0px 5px #80b9ff;
}

.sc-sidebar-slim-footer-shortcuts {
  background-color: #3980cc;
  border-radius   : 5px;
  color           : white;
  height          : 25px;
  min-width       : 62px;
}

.sc-sidebar-slim-footer-container {
  position      : absolute;
  bottom        : 0;
  padding-bottom: 10px;
  padding-left  : 2px;
  padding-right : 1px;
}

.sc-sidebar-slim-footer-tool {
  text-align    : center;
  vertical-align: middle;
  margin-top    : 5px;
}

.sc-sidebar-slim-footer-tool:hover {
  background-color: #ebebeb;
}

.sc-sidebar-slim-footer-label {
  font-size: 9pt;
  color: white;
}

.sc-sidebar-slim-footer-img {
  display: block;
  margin : 0 auto;
}

@media only screen and (max-width: 770px) {
  .sc-sidebar-slim-container {
    display: none;
  }
}